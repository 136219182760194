
import { ref, onMounted } from "vue";
import models from "@/models.json";
import processors from "@/processors.json";
import { enabledJobTypes, isFreeEnabled } from "@/lib/enabled-job-types";
import {
	getModelUrl,
	getModelFamily,
	getModelTime,
	getModelTags,
	getModelTitle
} from "@/lib/model-utils";
import { loadImage } from "@/lib/load-image";
import { loadedAssets } from "@/lib/model-assets";

import ModelTags from "@/components/ModelTags.vue";

export default {
	components: {
		ModelTags
	},
	setup: () => {
		const shuffledModels = [...models].sort((a, b) => {
			if (a.type.includes("schnell")) return -1;
			if (b.type.includes("schnell")) return 1;

			if (a.type.includes("mochi")) return -1;
			if (b.type.includes("mochi")) return 1;

			const aTime = getModelTime(a.type);
			const bTime = getModelTime(b.type);

			return aTime - bTime;
		});

		// get all the job types not included in models.json families (i.e. flux dev im2img _is_ included but faceswap is not)
		const utilityModels = enabledJobTypes
			.filter(
				(type) =>
					!models.some((model) => {
						return type.startsWith(getModelFamily(model.type));
					})
			)
			.filter(
				(type) =>
					!type.includes("flux-fast") &&
					!type.includes("flux-control") &&
					!type.includes("flux.pro")
			);

		console.log({ utilityModels });

		// preload all model assets
		onMounted(async () => {
			const _loadedAssets: any = {};

			await Promise.all(
				shuffledModels.map(async (model) => {
					const url = `/assets/models/${model.filename}`;

					_loadedAssets[model.filename] = await loadImage(url);
				})
			);

			loadedAssets.value = _loadedAssets;
		});

		return {
			models: shuffledModels,
			getModelTitle,
			getModelTags,
			getModelUrl,
			utilityModels,
			getModelTime,
			isFreeEnabled,
			loadedAssets
		};
	}
};
